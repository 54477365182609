<template>
    <b-form-group >
        <label for="surname-customer" class="input-label">{{ $t('checkout.surname') }}*</label>

        <validation-provider
            #default="{ errors }"
            name="lastname"
            rules="required"
        >
            <b-form-input
            v-model="customer.lastname"
            :state="errors.length > 0 ? false:null"
            id="surname-customer"
            placeholder="Doe"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        computed: {
            ...mapState('shop',['customer']) ,             
        },        
    }
</script>

<style lang="scss" scoped>

</style>