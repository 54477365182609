<template>
    <b-form-group >
        <label for="email-customer" class="input-label">{{ $t('checkout.email') }}*</label>
        <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
        >
            <b-form-input
            v-model="customer.email"
            :state="errors.length > 0 ? false:null"
            id="email-customer"
            placeholder="johndoe@mail.com"            
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    export default {
        computed: {
            ...mapState('shop',['customer']) ,             
        },
        methods: {
            ...mapActions('shop',['checkIfClientExists']),
            ...mapMutations('shop',['replaceCustomerData','removeIdCustomer']),  
                    
    
        },        
    }
</script>

<style lang="scss" scoped>

</style>