<template>
  <div>
    <Navigation/>    
    <div class="checkout-section" style="margin-top: 4rem;">
      <div class="checkout-section__form">
        <ValidationObserver ref="formCustomer" v-slot="{ invalid }" > 
          <b-form  @submit.prevent="handlerSubmit" >             
            <div class="checkout-section__form-header">
              <h1 class="title-form"> {{ $t('checkout.completeYouInformation') }} </h1>
              <h3 class="subtitle-title-form mb-2">
                {{ $t('checkout.beSureToFillInTheRequiredFields') }}  *
              </h3>
            </div>                             
            <b-overlay :show="isProcessingCheckout" rounded> 
              <b-row>                                        
                <b-col cols="12" md="6">
                  <CustomerName/>
                </b-col>

                <b-col cols="12" md="6">
                  <CustomerSurname/>                      
                </b-col>

                <b-col cols="12" md="6">
                  <CustomerEmail/>                                            
                </b-col>

                <b-col cols="12" md="6">
                  <CustomerPhoneNumber/>                                          
                </b-col>                                        
              </b-row>
            </b-overlay>  
            <div class="form-footer">
              <div>
                <div class="box-terms">
                  <!--  -->
                  <input id="checkbox" type="checkbox" class="box-terms__check" :value="true" v-model="isAccepted"   @change="setAcceptTerms"/>
                  <label for="checkbox" class="text-terms"> {{ $t('checkout.iAgreeToThese') }}</label> <span @click="showTermsConditions">
                    {{ $t('checkout.termsAndConditions') }} </span>.
                </div>
              </div>
              <div class="btns-footer">
                <b-button variant="primary" type="submit" :disabled="invalid || disabledBtnFinish || !isAccepetedTermsConditions" v-if="!orderCreated"  class="btn-blue"> 
                  {{ $t('checkout.proceedToPayment') }}
                </b-button>
              </div>
            </div>  
            <TermsConditions/>                      
          </b-form>
        </ValidationObserver> 
      </div>
      <div class="checkout-section__summary">
    
      <div class="customer-card">
                      
          <div class="checkout-section__summary__form-header">
            <b-card-title  class="title-form"> Summary </b-card-title>                                                                 
          </div>
                                                    
          <SummaryItemCheckout v-if="cart.length "/>
                                             
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required, email } from '@validations'
import { market } from '@/modules/shop/mixins/market'
import { localize } from 'vee-validate';


import Navigation from '@/modules/shop/components/experiences/NavigationLite'
import CustomerName from '@/modules/shop/components/checkout/CustomerName'
import CustomerSurname from '@/modules/shop/components/checkout/CustomerSurname'
import CustomerEmail from '@/modules/shop/components/checkout/CustomerEmail'
import CustomerPhoneNumber from '@/modules/shop/components/checkout/CustomerPhoneNumber'

import TermsConditions from '@/modules/shop/components/checkout/TermsConditions'

import SummaryItemCheckout from '@/modules/shop/components/checkout/SummaryItemCheckout'

import Footer from '@/modules/shop/components/experiences/FooterLite'

import { toDecimal } from '@/helpers/helpers'
import { processItemsCart }from '@/helpers/shopHelper'

export default {
  mixins:[market],
  components: {     
    Navigation, 
    CustomerName,
    CustomerSurname,
    CustomerEmail,
    CustomerPhoneNumber,
    TermsConditions,
    SummaryItemCheckout,   
    Footer,        
  },   
  async created(){  
    this.clearStorage()
  
    if( !this.hotels.length || !this.languages.length){
      const { hotels, languages } = await this.getInitialContentCheckout()
      this.setHotels(hotels)
      this.setLanguages(languages)
    }  
    if(!this.customerCurrency){
      this.setCurrencyCustomer({ currencyid: 1, code: "USD", value: "1.00" })
    }
    this.setAcceptTermsInitial()
    document.title = `${process.env.VUE_APP_NAME } | Checkout`  
    this.loadLocaleVeeValidate()
  },
  data() {
    return {      
      // validation rulesimport store from '@/store/index'
      required,
      email,
      //   email: ''          
      isProcessingCheckout : false,
      isAccepted:  false
    }
  },
  computed: {
    ...mapState('auth',['queryParams','user', 'isLogged']),                                
    ...mapState('start',['hotels','languages']),
    ...mapState('shop',['customer','cart', 'customerCurrency','breakdown','canalventa','puntoventa','orderCreated','isAccepetedTermsConditions']) , 
    ...mapGetters('shop',['totalDiscountOnAllProducts','totalDiscountOrder']), 
    disabledBtnFinish(){
      return (!this.cart.length || this.isProcessingCheckout )
    },           
  },
  methods: {   
    ...mapActions('shop',['getInitialContentCheckout','saveCustomer','makeSale']),
    ...mapMutations('start',['setHotels','setLanguages']),
    ...mapMutations('shop',['setInfoClient','setIdCustomer','setOrderCreated','resetCartCustomerBreakdownOrder','setUrlBankPaymentOrder','setCurrencyCustomer','setIsAccepetedTermsConditions']),    
    // inicial la variable local con lo que hay en el state
    setAcceptTermsInitial(){
      this.isAccepted = this.isAccepetedTermsConditions
    },      
    async handlerSubmit(){      
      this.isProcessingCheckout = true
      this.setDataCustomer() //muto el state de customer
      const { idCustomer } = this.customer      
      if(!idCustomer){
        const customerData = await this.saveCustomer(this.customer) //guardo el cliente, tomo su id u muto mi customer state        
        this.setIdCustomer(customerData.id) //tomo el id del cliente de customerData para ejcurtar la mutacion
      }
      const headersOrder = this.createHeadersOrder() //encabezados
      if(headersOrder.grossSale === '0.00'){ // Sí total de cuenta no pasa de 0
        headersOrder.paymentmethod = 1054 //Método de pago Not Applicable, como método de pago, no credit card 1
      }
      const detailsOrder = processItemsCart(this.cart, this.customer) // detalles de la orden
      const payload = { ...headersOrder, detail: [...detailsOrder] } //payload final, datos del cliente y productos del cart                 
      // console.log(payload)
      // return
      const orderCreated = await this.makeSale(payload)      
      this.setOrderCreated(orderCreated) //pongo en el state la orden generada      
      this.isProcessingCheckout = false 
      this.endSale(orderCreated) // si cuenta es 0 y no tarjeta de credito, debe ejecutarse este            
    }, 
    createHeadersOrder(){
      const { idCustomer, paymentreference, paymentmethod, paymentlink, hotel, customertype, customeragent, sihotCode, language } = this.customer
      const { currencyid } = this.customerCurrency      
      return {
        canalventa: process.env.VUE_APP_SALES_CHANNEL,// this.canalventa, 
        cargomanual: false,
        currency: currencyid,
        customer: idCustomer,
        customeragent, customertype,
        discount: this.totalDiscountOrder,
        grossSale: toDecimal( parseFloat( this.totalDiscountOnAllProducts ) + parseFloat( this.breakdown.total ) ),
        paymentamount: toDecimal( parseFloat( this.breakdown.total ) ),

        hotel, hotelsihotcode: sihotCode,
        language, numreserva: '', orderStatus: 1, paymentlink, paymentmethod, paymentreference,
        puntoventa:  process.env.VUE_APP_POINT_OF_SALE, // this.puntoventa,
        saleUser: process.env.VUE_APP_ID_SALE_USER, // this.user.idUser,// 
        seccioncanal: process.env.VUE_APP_SECTION_CHANNEL,// this.user.sectionCanal.id, // TODO: canal venta a Definir
        tax: "0.00",
        totalSale: this.breakdown.total,
      }
    },    
    setDataCustomer(){      
      this.customer.idUser = process.env.VUE_APP_ID_SALE_USER  // TODO, se requiere uno fijo, ya que no se tiene un user conectado
      this.customer.customertype = process.env.VUE_APP_CUSTOMER_TYPE || 8 // tipo de cliente es Directo Web
      this.customer.paymentmethod = process.env.VUE_APP_PAYMENT_METHOD || 1 // 1 is credit card                
      this.customer.sihotCode = null // hotel !== null ? this.hotels.find( h => h.id === hotel ).sihotCode : null
      this.customer.sellercanalventa =  process.env.VUE_APP_SALES_CHANNEL // this.user.canalventa.id // se requiere uno fijo
      this.setInfoClient( this.customer )
    },      
    endSale(payload){
      const {status, order, invoice, payment } = payload      
      if(status && order != '' && invoice != '' && !payment ){ //si hay un invoice, status true, hay una orden y payment es nulo
        this.$swal.fire('Operation completed', `🎉🎉 The order has been completed: ${invoice}. 🎉🎉`, 'success')
        this.resetCartCustomerBreakdownOrder(null)
        this.$refs.formCustomer.reset() //reseteo form  
      }
    },
    showTermsConditions(){      
      this.$root.$emit("bv::show::modal", "modal-terms-conditions")
    },
    setAcceptTerms(){      
      this.setIsAccepetedTermsConditions( this.isAccepted )        
    },
    loadLocaleVeeValidate(){
      const lang = this.$i18n.locale
      const langsValid = ['en', 'es', ] // 'pt' para portuguese, idiomas validos a usar
      const langValid = langsValid.includes(lang)
      const codeLang = langValid ? lang : 'en'
      return import(`vee-validate/dist/locale/${codeLang}.json`).then( locale => { localize(codeLang, locale) })      
    }
  },
  watch: {            
    orderCreated: { // '$route.query.idItem'
      immediate: true,
      handler(newVal ) {
        let existUrl = false
        if( this.orderCreated ) {
          const { payment } = this.orderCreated
          if(payment){
            const { error, url } = payment
            existUrl = ( this.orderCreated && payment && !error && url != '' )
          }
        }  
        if(existUrl){
          this.$router.push({ name:'payment', query: {...this.queryParams} }) // si existe url de banco lo manda a url de banco            
        }               
      }
    }
  },
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/checkout.scss";
</style>
