<template>
    <b-form-group>
        <label for="name-customer" class="input-label">{{ $t('checkout.name') }}*</label>

        <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
        >
            <b-form-input
            v-model="customer.name"
            :state="errors.length > 0 ? false:null"
            id="name-customer"
            placeholder="John"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        computed: {
            ...mapState('shop',['customer']) ,             
        },        
    }
</script>

<style lang="scss" scoped>

</style>