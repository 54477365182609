import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { toJson, stringAleatorio, currentDate, removeNullValuesFromAnObject, currentDateAddOneDay} from '@/helpers/helpers'
import { version } from '../../../../package'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

export const market = {
  data () {
    return { 
      params: this.$route.query,      
      codesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],      
    }
  }, 
  computed:{    
    ...mapState('products',['products','bestSellers']),    
    ...mapState('shop',['cart','orderCreated']),       
    ...mapState('auth',['queryParams','lang']), 
    ...mapGetters('shop',['isEmptyCart']),
    idItemDetail(){
      return this.$route.query.idItem ? this.$route.query.idItem : null
    },
    dateFormatted(){
      const langLocale = this.lang
      const langs = { en: 'en-US', es:'es-MX' }
      if(this.queryParams){
        const defaultDateOptions = {
          day: 'numeric',
          weekday: 'long', // short/long
          month: 'short', // short/long
          year:'numeric'
        }
        const date = new Date( this.queryParams.date+'T00:00:00' )
        return  new Intl.DateTimeFormat(langs[langLocale], defaultDateOptions ).format( date ) // langs[langLocale] es langs.en o langs.es
      }
    },  
                         
  },
  methods: {                                   
    ...mapMutations('start',['setSelectedCategory','setSelectedDestination']),
    ...mapMutations('auth',['setDataQueryParams','setLangApplication']),  
    ...mapMutations('products',['setProductDetail']),
    ...mapMutations('shop',['setLanguageCustomer','addProductInCart','clearCart','calculateTotalsItemsCart','setOrderCreated']),    
    setQueryParams(){
      const hasQueryParams = Object.keys(this.$route.query).length > 0
      let queryParams = null                   
      // si no viene parametros, poner en el state default values
      if(!hasQueryParams){
        queryParams = toJson( removeNullValuesFromAnObject( this.getDefaultParams() ))   
        this.setLangAppDefault()
        queryParams.lang = 'en'
        this.setDataQueryParams(queryParams) 
        this.setSelectedCategory({ idCategory: 0, idSubCategory: null })   
        this.setSelectedDestination( { id:0, name: this.$t("products.allDestinations")  })
      }
      // si viene parametros, tomar este valor y ponerlo en el state
      if(hasQueryParams){                            
        queryParams = toJson( removeNullValuesFromAnObject( this.$route.query )) 
        const currentDatePlus = currentDateAddOneDay()
        //para validar que en el query params no venga fechas atrasadas
        const date = queryParams.date ? queryParams.date : currentDatePlus //sino viene date en el queryString           
        queryParams.date = this.givenDateIsGreaterThanToday( date ) ?  date: currentDatePlus
        //manejo de pax
        queryParams.adults = this.isANumber(queryParams.adults) && queryParams.adults > 0 ? parseInt(queryParams.adults) : 1 //sino vienen adultos en en el queryString
        queryParams.children = this.isANumber(queryParams.children) && queryParams.children > 0  ? parseInt(queryParams.children) : 0 //sino vienen niños en en el queryString        
        queryParams.destination = this.isANumber(queryParams.destination) && queryParams.destination >= 0  ? parseInt(queryParams.destination) : 0 //sino viene destination en el queryString      
        if(queryParams.lang){
          this.setLangApp(queryParams.lang)
        } 
        if(!queryParams.lang){
          this.setLangAppDefault()
          queryParams.lang = 'en'
        }

        const queryParamsWithCategory = this.queryCategorySubCategory({...queryParams})        
        //si viene category en 0, no es viable poner una condicion así queryParamsWithCategory.category ? queryParamsWithCategory.category : null
        const categorySelected = {
          idCategory: queryParamsWithCategory.category != null ? queryParamsWithCategory.category : null,
          idSubCategory: queryParamsWithCategory.subCategory != null ? queryParamsWithCategory.subCategory : null
        }     
        if( queryParamsWithCategory.destination === 0 ){
          this.setSelectedDestination( { id:0, name: this.$t("products.allDestinations") })
        }   
        this.setSelectedCategory(categorySelected)               
        this.setDataQueryParams(queryParamsWithCategory)            
      }        
    },
    handlerLanguage(lang){
      const langsValid = ['en', 'es', ] // 'pt' para portuguese, idiomas validos a usar
      const langValid = langsValid.includes(lang)
      const langToUse = langValid ? lang : 'en'           
      return langToUse   
    },
    setLangApp(locale){
      const idLangs =  { en: 1, es: 2 }
      const lang = this.handlerLanguage(locale)           
      this.$i18n.locale = lang
      this.setLangApplication( lang ) //si viene lang con valor          
      this.setLanguageCustomer( idLangs[lang] ) //idLangs.en = 1 
    },
    setLangAppDefault(){             
      this.$i18n.locale = 'en'
      this.setLangApplication( 'en' ) //si viene lang con valor          
      this.setLanguageCustomer( 1 ) // 1 es ingles
    },
    queryCategorySubCategory(queryParams) {
      const { category, subCategory } = queryParams
      if(subCategory){
        queryParams.subCategory = this.isANumber(subCategory) ? parseInt(subCategory) : null
        queryParams.category = null
      }
      if(category){
        queryParams.category = this.isANumber(category) ? parseInt(category) : null
        queryParams.subCategory = null
      }
      if( !category && !subCategory ){
        queryParams.category = 0
        queryParams.subCategory = null
      }

      return removeNullValuesFromAnObject(queryParams)
    },
    isANumber(string){
      return string ? !isNaN( string) : false
    },    
    givenDateIsGreaterThanToday(date){
      const today = new Date( currentDate() )
      const givenDate = new Date( date )
      return givenDate > today
    },
    getDefaultParams(){        
      return {
        date: currentDateAddOneDay(),          
        adults: 1,
        children: 0,
        category: 0, //0 es para obtener los bestsellers
        subCategory: null,
        idItem: null,
        destination: 0, //0 es all destinations
      }
    },
    setQueryStringInUrl(){     
      //del state se toma los queryStrings in se setea en la url   
      this.$router.replace({ query: {...this.queryParams} }).catch(() => {})
    },
    clearStorage(){
      const localVersion = localStorage.getItem('appVersion')
      if(!localVersion){
        console.log('clearStorage, remover vuex', version)
        localStorage.removeItem('vuex') //clear vuex
        localStorage.setItem('appVersion', version)
        window.location.reload(true)
      }
      if( localVersion ){
        if( localVersion !== version ){
          console.log('no son versiones iguales')
          localStorage.removeItem('vuex') //clear vuex
          localStorage.setItem('appVersion', version)
          window.location.reload(true)
        }
      }
    },       
    formatProductBeforeAddingToCart(product){

      let adultDetail
      let childDetail

      if( product.isLegacy ){
        adultDetail = product.detail.find( d => d.detailDesc.toLowerCase() == 'adult' || d.detailDesc.toLowerCase() == 'adulto' ) //para adulto
        childDetail = product.detail.find( d => d.detailDesc.toLowerCase() === 'child' || d.detailDesc.toLowerCase() === 'menor' ) //para menor
      }

      if( !product.isLegacy ){

        const hasTypeOneTwo = product.detail.some(item => item.type === 1 || item.type === 2)

        if( hasTypeOneTwo ){
          adultDetail = product.detail.find( d => d.typeDisplay === 'adult' ) 
          childDetail = product.detail.find( d => d.typeDisplay === 'child' ) 
        }
        if( !hasTypeOneTwo ){
          adultDetail = product.detail.find( item => item.type === 3 )
        }

      }
      //DONDE REMUEVO Y AÑADO ELEMENTOS ANTES DE AÑADIR AL CART      
      const { descmax, detaildisplay, id, saleprice, servicesihot, qty, typeDisplay, cost } = adultDetail
      product.descmax = parseInt(descmax) || 0
      product.detaildisplay = detaildisplay   
      product.detailId = id
      product.typeDisplay = typeDisplay

      product.detaildisplayChild = childDetail ? childDetail.detaildisplay : null 
      product.detailIdChild = childDetail ? childDetail.id : null
      product.saleprice = saleprice
      product.salepriceChild = childDetail ? childDetail.saleprice : '0.00'
      product.serviciosihot = servicesihot || null
      product.discountApplied = 0
      product.itinerario = {}
      product.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}
      //mas deletes
      delete product.category
    // delete product.coverImage
      delete product.detail
      delete product.currencyCode
      delete product.type
      delete product.gallery
      delete product.videolink
      delete product.weblink
      delete product.productinfo
      delete product.terms
      delete product.servicesihot
      delete product.currency
      delete product.hotel
      delete product.metatag
      delete product.location
      delete product.startDate
      delete product.startTime
      delete product.endDate
      delete product.endDateFormat
      delete product.endTimeFormat
      delete product.hotelName
      delete product.locationName
      delete product.startDateFormat
      delete product.startTimeFormat
      
      //AÑADO PROPIEDADES ADICIONALES
      product.uuid = stringAleatorio() //les genero key unicos aleatorios
      product.salepriceWithDiscount = saleprice
      product.originalSalePrice = saleprice
      product.qty = qty || 1
      product.qtyChildren = childDetail?.qty || 1

      product.cost = cost || '0.00'
      product.costChildren = childDetail?.cost || '0.00'

      product.operationdate = this.queryParams.date
      product.operationtime = '12:00'
      product.note = ''
      product.pax = typeDisplay === 'group' ? qty : parseInt( this.queryParams.adults )
      product.typePax = 'adult'            
      product.destination = this.queryParams.destination ? parseInt( this.queryParams.destination ) : null
      product.clientnote = '',
      product.requiredFieldsFilled = false
      product.orderdetailbeo = { titulo: '', backup: null, locacion: null, comentario: '', pax: 0, numreserva: '', detailbeo: [] },

      //REMUEVO detailSelected
      delete product.detail
      delete product.detailSelected
      return product
    },
    formatPhoneNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which      
      const respuesta = this.codesAllowed.includes(keyCode)
      if (!respuesta) {
        event.preventDefault()
      }
    },   
    initAddToCart(item){      
      const paxChildren = parseInt( this.queryParams.children )      
      // siempre está vacío el cart
      const productAdults = this.formatProductBeforeAddingToCart(item) //agrega a  
      const productChildren = this.handlerItemChildren( {...productAdults}  )
      const items = paxChildren > 0 && productChildren.detailId !== null ? [ productAdults, productChildren ] : [ productAdults ]
      this.addProductInCart(items) // agrego el producto formateado        
            
      if(this.orderCreated){
        this.setOrderCreated(null)
      }
    },
    handlerItemChildren(product){
      const paxChildren = parseInt( this.queryParams.children )      
      product.uuid = stringAleatorio()
      product.typePax = 'child'                    
      product.pax = paxChildren  
      product.qty = paxChildren 
      product.cost = product.costChildren 
      product.saleprice = product.salepriceChild
      product.originalSalePrice = product.salepriceChild
      product.salepriceWithDiscount = product.salepriceChild      
      product.salepriceWithDiscount = product.salepriceChild     
      product.detaildisplay = product.detaildisplayChild      
      product.detailId = product.detailIdChild       
      return product      
    },
    calculateBreakdownInCart(){
      if(this.cart.length){        
        this.calculateTotalsItemsCart()
      }
    },
    menuHamburguer(){
      const navbarMenu = document.getElementById("menu")
      const burgerMenu = document.getElementById("burger")
      const bgOverlay = document.querySelector(".overlay")

      if (burgerMenu && bgOverlay) {
      burgerMenu.addEventListener("click", () => {
        navbarMenu.classList.add("is-active")
        bgOverlay.classList.toggle("is-active")
      })

      bgOverlay.addEventListener("click", () => {
        navbarMenu.classList.remove("is-active")
        bgOverlay.classList.toggle("is-active")
      })
      }
      // Close Navbar Menu on Links Click
      document.querySelectorAll(".menu-link").forEach((link) => {
      link.addEventListener("click", () => {
        navbarMenu.classList.remove("is-active")
        bgOverlay.classList.remove("is-active")
      })
      })   
    },
    overWriteTokenAxios(){      
      const langLocale = this.$i18n.locale
      const tokens =  { en: process.env.VUE_APP_API_TOKEN_EN, es: process.env.VUE_APP_API_TOKEN_ES }
      const langsValid = ['en', 'es', ] // 'pt' para portuguese, idiomas validos a usar
      const isValidLang = langsValid.includes(langLocale)
      const codeLang = isValidLang ? langLocale : 'en'
      const tokenToUse = tokens[codeLang]
      //sobreescribo los datos en axios, una vez obtenido un token
      axios.defaults.headers.common['Authorization'] = 'Token '+ tokenToUse      
      Vue.use(VueAxios, axios)// end      
    }  
  },
  watch: {            
    idItemDetail: { // '$route.query.idItem'
      // immediate: true,
      handler(newVal ) {
        //this.setProductDetail(null) //si hay algo en detalle de producto lo limpio   
        //console.log('cambio')     
      }
    }
  },
 
}



