<template>
   
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
</template>
<script> 
    export default {
       props:{
            color:{
                required: false,
                default: '#18181b'
            },
            width:{
                required: false,
                default: 24                
            },
            height:{
                required: false,
                default: 24
            },                           
       } 
    }
</script>