<template>
    <b-form-group>
        <label for="phone-customer" class="input-label">{{ $t('checkout.phone') }}*</label>
        <validation-provider
            #default="{ errors }"
            name="phone"
            rules="required"
        >
            <b-form-input
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="20"
                @keypress="formatPhoneNumber"
                type="text"
                v-model="customer.phone"
                :state="errors.length > 0 ? false:null"
                id="phone-customer"
                placeholder="983-54-53-30-8"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>
</template>

<script>
    import { mapState } from 'vuex'
    import { market } from '@/modules/shop/mixins/market'

    export default {
        mixins:[market],
        computed: {
            ...mapState('shop',['customer']) ,             
        },        
    }
</script>

<style lang="scss" scoped>

</style>