<template>
   <b-modal  scrollable id="modal-terms-conditions" title="Terms and Conditions" ok-only  size="lg">

    <div v-if="$i18n.locale ==='en'">
      <h3>Our Services:</h3>   
      <p>
        All our services are guaranteed and are not transferable, likewise, Mar Holidays or the operator will reserve the right of admission to customers who do not meet the guidelines established for each activity, for this case, a refund will not proceed.
      </p> 

      <h3>Rescheduling:</h3>   
      <p>This service may be rescheduled with at least 24 hrs. in advance under any of the following conditions:</p>
      <ol>
        <li>Availability of new date.</li>
        <li>Operator's own terms.</li>
      </ol>

      <h3>Cancellation:</h3>
      <p>This service may be cancelled at least 24 hrs. in advance under any of the following conditions:</p>
      <ol>
        <li>Presenting individual medical voucher.</li>
        <li>Operator's own terms.</li>
      </ol>

      <h3>Refund:</h3>
      <p>
        In case the client does not perform the contracted service, no refund will apply. In case the operator is unable to provide the contracted service either partially or completely, the reimbursement indicated by the operator will be made.
      </p>
    </div>      

    <div v-if="$i18n.locale ==='es'">
      <h3>Nuestros Servicios:</h3>   
      <p>
        Todos nuestros servicios son garantizados y no son transferibles, así mismo, Mar Holidays o el operador se reservarán el derecho de admisión a clientes que no cumplan con los lineamientos establecidas para cada actividad, para este caso, no procederá un reembolso.
      </p> 

      <h3>Reagenda:</h3>   
      <p>Este servicio puede ser reagendado con por lo menos 24 hrs. de antelación bajo alguna de las siguientes condiciones:</p>
      <ol>
        <li>Disponibilidad de nueva fecha.</li>
        <li>Términos propios del operador.</li>
      </ol>

      <h3>Cancelación:</h3>
      <p>Este servicio puede ser cancelado con por lo menos 24 hrs. de antelación bajo alguna de las siguientes condiciones:</p>
      <ol>
        <li>Presentando comprobante médico individual.</li>
        <li>Términos propios del operador.</li>
      </ol>

      <h3>Reembolso:</h3>
      <p>
        En caso de que el cliente no realice el servicio contratado, no aplicará ningún reembolso. En caso de que el operador no pueda prestar el servicio contratado ya sea de forma parcial o completa, se procederá con el reembolso indicado por el operador.
      </p>    
    </div>                        

  </b-modal>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>